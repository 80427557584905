import React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import "./TopFiveUsers.css";

const columns = [
  { id: "username", label: "User", minWidth: 170 },
  { id: "command_count", label: "Command Count", minWidth: 170 },
  { id: "regulators", label: "Regulators", minWidth: 170 },
];

export default function AuditTopFiveUsers({ data }) {
  const rows =
    data.length > 0
      ? data.slice(0, 5).map((user) => ({
          username: user.username,
          command_count: user.command_count,
          regulators: user.regulators.join(", "),
        }))
      : [
          {
            username: "No users available",
            command_count: 0,
            regulators: "None",
          },
        ];
return (
  <div className="top-5-user">
    <h2>Top Five Users</h2>
    <Paper className="audit-table-container">
      <TableContainer className="audit-table-container">
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  className="audit-table-header"
                  align="center"
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                {columns.map((column) => {
                  const value = row[column.id];
                  return (
                    <TableCell
                      key={column.id}
                      align="center"
                      className="audit-table-cell"
                    >
                      {value}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  </div>
);
}
