import React from "react";
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from "recharts";
import "./RegulatorWiseUsage.css";

const generateColor = (index) => {
  const colors = ["#8884d8", "#82ca9d", "#ffc658", "#ff8042", "#ff7300", "#66cdaa", "#db7093", "#6a5acd"];
  return colors[index % colors.length];
};

const RegulatorWiseUsage = ({ regulatorUsage }) => {
  const data =
    regulatorUsage.length > 0
      ? regulatorUsage
      : [{ regulator: "No regulators available", usage_count: 0 }];

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      return (
        <div className="audit-custom-tooltip">
          <p>{`Regulator: ${data.regulator}`}</p>
          <p>{`Usage Count: ${data.usage_count}`}</p>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="audit-regulator-usage">
      <h2>Regulator Wise Usage</h2>
      <ResponsiveContainer width="100%" height={300}>
        <PieChart>
          <Pie
            data={data}
            dataKey="usage_count"
            nameKey="regulator"
            cx="50%"
            cy="50%"
            outerRadius={120}
            label
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={generateColor(index)} />
            ))}
          </Pie>
          <Tooltip content={<CustomTooltip />} />
        </PieChart>
      </ResponsiveContainer>
      <div className="audit-legend">
        {data.map((entry, index) => (
          <div key={entry.regulator} className="audit-legend-item">
            <span
              className="audit-legend-color"
              style={{ backgroundColor: generateColor(index) }}
            />
            {entry.regulator}
          </div>
        ))}
      </div>
    </div>
  );
};

export default RegulatorWiseUsage;