import React, { useEffect, useState, useRef } from "react";
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import { useTheme } from '@mui/material/styles';
import TopFiveUsers from "./TopFiveUsers/TopFiveUsers";
import RegulatorWiseUsage from "./RegulatorWiseUsage/RegulatorWiseUsage";
import CommandWiseUsage from "./CommandWiseUsage/CommandWiseUsage";
import DetailedLogsTable from "./DetailedLogsTable/DetailedLogsTable";
import SummaryCard from "./SummaryCard/SummaryCard";
import UserProfileDropdown from "./ComplieazeHeader/ComplieazeHeader";

import "./Audit.css";

function Audit() {
  // State Management
  const [data, setData] = useState(null);
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Filter States
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [isCustom, setIsCustom] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedRegulators, setSelectedRegulators] = useState([]);
  const [selectedCommands, setSelectedCommands] = useState([]);

  const [userList, setUserList] = useState([]);
  const [regulatorsList, setRegulatorsList] = useState([]);
  const [operationsList, setOperationsList] = useState([]);

  // Pagination States
  const [page, setPage] = useState(0); // Zero-based indexing for frontend
  const [perPage, setPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const dropdownRef = useRef(null);

  // Format Date Utility Function
  function formatDate(date) {
    return `${date.getFullYear()}-${(date.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
  }

  const today = new Date();
  const todayFormatted = formatDate(today);

  // Handle click outside to close dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleBoxClick = () => {
    setShowDropdown((prev) => !prev);
  };

  const updateDateRange = (option) => {
    const today = new Date();
    let start, end;

    switch (option) {
      case "Today":
        start = end = today;
        break;
      case "Yesterday":
        start = end = new Date(today.getTime() - 24 * 60 * 60 * 1000);
        break;
      case "Last Week":
        start = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
        end = today;
        break;
      case "Last Month":
        start = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate());
        end = today;
        break;
      case "Last Year":
        start = new Date(today.getFullYear() - 1, today.getMonth(), today.getDate());
        end = today;
        break;
      case "Custom":
        setIsCustom(true);
        setSelectedOption("Custom"); // Set selectedOption to "Custom"
        setStartDate(""); // Reset startDate
        setEndDate(""); // Reset endDate
        return;
      default:
        return;
    }

    setIsCustom(false);
    setStartDate(formatDate(start));
    setEndDate(formatDate(end));
    setSelectedOption(option);
  };

  const handleOptionClick = (option) => {
    updateDateRange(option);
    // setShowDropdown(false); // Close the dropdown after selecting an option
  };

  const handleCustomDateChange = (e, isStart) => {
    if (isStart) {
      setStartDate(e.target.value);
    } else {
      setEndDate(e.target.value);
    }
  };

  const handleClose = () => {
    setShowDropdown(false);
  };

  const fetchInitialData = async () => {
    setLoading(true);
    setError(null);
    try {
      // Fetch initial audit data
      await fetchLogs(page, perPage);
      // Fetch filter options
      await fetchOptions();
    } catch (err) {
      setError(err.message || "An error occurred");
    } finally {
      setLoading(false);
    }
  };

  const fetchOptions = async () => {
    try {
      const [userResponse, regulatorResponse, commandResponse] = await Promise.all([
        fetch("/users_list"),
        fetch("/rbac/get_regulators"),
        fetch("/rbac/get_operations"),
      ]);

      if (!userResponse.ok || !regulatorResponse.ok || !commandResponse.ok) {
        throw new Error("Failed to fetch filter options");
      }

      const [users, regulators, commands] = await Promise.all([
        userResponse.json(),
        regulatorResponse.json(),
        commandResponse.json(),
      ]);

      setUserList(users.map(email => ({ value: email, label: email })));
      setRegulatorsList(regulators.map(item => ({ value: item.regulator, label: item.regulator })));
      setOperationsList(commands.map(op => ({ value: op, label: op === "action" ? "checklist" : op })));
    } catch (err) {
      console.error("Error fetching options:", err);
      setError("Failed to fetch filter options");
    }
  };

  const clearSelectedOption = () => {
    setSelectedOption("");
    setStartDate("");
    setEndDate("");
    setIsCustom(false);
  };

  const buildPayload = (currentPage, currentPerPage) => {
    const payload = {
      username: selectedUsers.map(user => user.value || user),
      command: selectedCommands.map(command => command.value || command),
      regulator: selectedRegulators.map(regulator => regulator.value || regulator),
      page: currentPage + 1, // Convert zero-based to one-based indexing
      per_page: currentPerPage,
    };
    if (startDate && endDate) {
      payload.start_date = startDate;
      payload.end_date = endDate;
    }
    return payload;
  };

  const fetchLogs = async (currentPage, currentPerPage, additionalFilters = {}) => {
    setLoading(true);
    setError(null);
    try {
      const payload = buildPayload(currentPage, currentPerPage);
      console.log("Fetching logs with payload:", payload); // Debugging

      // Fetch main audit log
      const response = await fetch("/get_audit_log", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json();
      console.log("Audit Log Result:", result); // Debugging
      setData(result);

      // Fetch user audit log with the same payload
      const userAuditResponse = await fetch("/get_users_audit", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      });

      if (!userAuditResponse.ok) {
        throw new Error(`HTTP error! Status: ${userAuditResponse.status}`);
      }

      const userAuditResult = await userAuditResponse.json();
      console.log("User Audit Result:", userAuditResult); // Debugging
      setLogs(userAuditResult.data || []);
      setTotalCount(userAuditResult.total_count || 0);
    } catch (err) {
      setError(err.message || "An error occurred while fetching logs");
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    setPage(0); // Reset to first page on new filter submission (zero-based)
    await fetchLogs(0, perPage);
  };

  const handleChangePage = async (event, newPage) => {
    setPage(newPage);
    await fetchLogs(newPage, perPage);
  };

  const handleChangeRowsPerPage = async (event) => {
    const newPerPage = parseInt(event.target.value, 10);
    setPerPage(newPerPage);
    setPage(0); // Reset to first page
    await fetchLogs(0, newPerPage);
  };

  useEffect(() => {
    fetchInitialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) return (
    <div className="audit-loader-container">
      <div className="audit-loader">
        <div className="audit-loader-circle"></div>
        <span>Loading...</span>
      </div>
    </div>
  );  
  if (error) return <div className="error">Error: {error}</div>;

  const auditCounts = data.audit_counts ? data.audit_counts[0] : { user_count: 0, command_count: 0, regulator_count: 0 };
  const { user_count, command_count, regulator_count } = auditCounts;

  return (
    <div className="audit-container">
      {/* <div className="profilemenu"> */}
      <UserProfileDropdown/>
      {/* </div> */}
      <div className="audit-header">
        <div className="audit-header-filters">
          {/* Time Filters */}
          <div className="audit-time-filters">
            <div className="time-filter-container" ref={dropdownRef}>
              <div className="time-filter-box" onClick={handleBoxClick}>
                {selectedOption === "Custom"
                  ? `${startDate} - ${endDate}`
                  : selectedOption || "Select a Period"}
                {selectedOption && (
                  <button
                    className="clear-button"
                    onClick={(e) => {
                      e.stopPropagation(); // Prevents triggering the dropdown when clicking the clear button
                      clearSelectedOption();
                    }}
                  >
                    &times;
                  </button>
                )}
              </div>

              {showDropdown && (
                <div className="time-filter-dropdown">
                  <div className="dropdown-header">
                    <span>Time Filter</span>
                    <button className="close-button" onClick={handleClose}>
                      &times;
                    </button>
                  </div>

                  <div className="dropdown-content">
                    <div className="time-filter-menu">
                      <ul>
                        {["Today", "Yesterday", "Last Week", "Last Month", "Last Year", "Custom"].map(
                          (option) => (
                            <li
                              key={option}
                              className={`time-filter-option ${selectedOption === option ? "active" : ""
                                }`}
                              onClick={() => handleOptionClick(option)}
                            >
                              {option}
                            </li>
                          )
                        )}
                      </ul>
                    </div>

                    {isCustom && (
                      <div className="custom-date-range">
                        <div className="date-input">
                          <label htmlFor="start-date">Start Date</label>
                          <input
                            id="start-date"
                            type="date"
                            value={startDate}
                            onChange={(e) => handleCustomDateChange(e, true)}
                            max={todayFormatted}
                          />
                        </div>
                        <div className="date-input">
                          <label htmlFor="end-date">End Date</label>
                          <input
                            id="end-date"
                            type="date"
                            value={endDate}
                            onChange={(e) => handleCustomDateChange(e, false)}
                            max={todayFormatted}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* Dropdown Filters */}
          <div className="audit-dropdown-filters">
            {/* Select User */}
            <FormControl sx={{ m: 2, width: 200 }}>
              <InputLabel id="select-user-label">Select a User</InputLabel>
              <Select
                labelId="select-user-label"
                id="select-user"
                multiple
                value={selectedUsers}
                onChange={(event) => {
                  const value = event.target.value;
                  if (value.includes("select-all")) {
                    if (selectedUsers.length === userList.length) {
                      setSelectedUsers([]);
                    } else {
                      setSelectedUsers(userList.map(user => user.value));
                    }
                  } else {
                    setSelectedUsers(value);
                  }
                }}
                renderValue={(selected) =>
                  selected
                    .map((user) => userList.find((u) => u.value === user)?.label)
                    .join(", ")
                }
                input={<OutlinedInput label="Select a User" />}
              >
                <MenuItem key="select-all" value="select-all">
                  <Checkbox
                    checked={selectedUsers.length === userList.length && userList.length > 0}
                    indeterminate={
                      selectedUsers.length > 0 && selectedUsers.length < userList.length
                    }
                  />
                  <ListItemText primary="Select All" />
                </MenuItem>
                {userList.map((user) => (
                  <MenuItem key={user.value} value={user.value}>
                    <Checkbox checked={selectedUsers.indexOf(user.value) > -1} />
                    <ListItemText primary={user.label} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {/* Select Regulator */}
            <FormControl sx={{ m: 2, width: 200 }}>
              <InputLabel id="select-regulator-label">Select a Regulator</InputLabel>
              <Select
                labelId="select-regulator-label"
                id="select-regulator"
                multiple
                value={selectedRegulators}
                onChange={(event) => {
                  const value = event.target.value;
                  if (value.includes("select-all")) {
                    if (selectedRegulators.length === regulatorsList.length) {
                      setSelectedRegulators([]);
                    } else {
                      setSelectedRegulators(regulatorsList.map(reg => reg.value));
                    }
                  } else {
                    setSelectedRegulators(value);
                  }
                }}
                renderValue={(selected) =>
                  selected
                    .map((reg) => regulatorsList.find((r) => r.value === reg)?.label)
                    .join(", ")
                }
                input={<OutlinedInput label="Select a Regulator" />}
              >
                <MenuItem key="select-all" value="select-all">
                  <Checkbox
                    checked={selectedRegulators.length === regulatorsList.length && regulatorsList.length > 0}
                    indeterminate={
                      selectedRegulators.length > 0 &&
                      selectedRegulators.length < regulatorsList.length
                    }
                  />
                  <ListItemText primary="Select All" />
                </MenuItem>
                {regulatorsList.map((regulator) => (
                  <MenuItem key={regulator.value} value={regulator.value}>
                    <Checkbox checked={selectedRegulators.indexOf(regulator.value) > -1} />
                    <ListItemText primary={regulator.label} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {/* Select Command */}
            <FormControl sx={{ m: 2, width: 200 }}>
              <InputLabel id="select-command-label">Select a Command</InputLabel>
              <Select
                labelId="select-command-label"
                id="select-command"
                multiple
                value={selectedCommands}
                onChange={(event) => {
                  const value = event.target.value;
                  if (value.includes("select-all")) {
                    if (selectedCommands.length === operationsList.length) {
                      setSelectedCommands([]);
                    } else {
                      setSelectedCommands(operationsList.map(op => op.value));
                    }
                  } else {
                    setSelectedCommands(value);
                  }
                }}
                renderValue={(selected) =>
                  selected
                    .map((cmd) => operationsList.find((o) => o.value === cmd)?.label)
                    .join(", ")
                }
                input={<OutlinedInput label="Select a Command" />}
              >
                <MenuItem key="select-all" value="select-all">
                  <Checkbox
                    checked={selectedCommands.length === operationsList.length && operationsList.length > 0}
                    indeterminate={
                      selectedCommands.length > 0 &&
                      selectedCommands.length < operationsList.length
                    }
                  />
                  <ListItemText primary="Select All" />
                </MenuItem>
                {operationsList.map((command) => (
                  <MenuItem key={command.value} value={command.value}>
                    <Checkbox checked={selectedCommands.indexOf(command.value) > -1} />
                    <ListItemText primary={command.label} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          {/* Submit Button */}
          <button className="audit-filter-button" onClick={handleSubmit}>
            Submit
          </button>
        </div>
      </div>

      {/* Summary Cards */}
      <div className="audit-summary-cards">
        <SummaryCard title="Active Users" value={user_count} />
        <SummaryCard title="Active Commands" value={command_count} />
        <SummaryCard title="Active Regulators" value={regulator_count} />
      </div>

      {/* Main Content */}
      <div className="audit-main-content">
        <div className="audit-dashboard-content">
          <div className="audit-charts-row">
            <div className="audit-chart-column">
              <TopFiveUsers data={data.top_users} />
            </div>
            <div className="audit-chart-column">
              <CommandWiseUsage commandUsage={data.command_usage} user_count={user_count} />
            </div>
            <div className="audit-chart-column">
              <RegulatorWiseUsage regulatorUsage={data.regulator_usage} />
            </div>
          </div>
          <div className="audit-logs-section">
            <DetailedLogsTable
              logs={logs}
              totalCount={totalCount}
              page={page}
              rowsPerPage={perPage}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Audit;
