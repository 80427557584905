// UserProfileDropdown.js
import React, { useState , useEffect,useRef} from 'react';
import axios from 'axios';
import html2pdf from 'html2pdf.js'; // Import html2pdf.js for PDF generation
import './ComplieazeHeader.css'

const UserProfileDropdown = () => {
  const [isUserProfileDropdownOpen, setIsUserProfileDropdownOpen] = useState(false);
  const [userDetails, setUserDetails] = useState(null);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false); // State for loader
  const dropdownRef = useRef(null);

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await axios.get('/get_username');
        const data = response.data;
        setUserDetails(data);
      } catch (error) {
        console.error('Error fetching user details:', error);
      }
    };
    fetchUserDetails();
  }, []);

  // const toggleUserProfileDropdown = () => {
  //   setIsUserProfileDropdownOpen(!isUserProfileDropdownOpen);
  // };
  const toggleUserProfileDropdown = () => {
    setIsUserProfileDropdownOpen((prevState) => !prevState);
  };


  useEffect(() => {
    // Fetch super admin status
    axios
      .get("/rbac/is_super_admin")
      .then((response) => {
        setIsSuperAdmin(response.data.is_super_admin);
      })
      .catch((error) => {
        console.error("Error fetching super admin status:", error);
      });
  }, []);

  const handleUserOptionClick = async (option) => {
    switch (option) {
      case 'Prompt':
        window.location.href = '/chat';
        break;

      case 'Admin':
        window.location.href = '/admin';
        break;
      
      case 'Logout':
        try {
          const authTypeResponse = await fetch('/logout_type', {
            method: 'POST',
            credentials: 'include',
          });
          if (!authTypeResponse.ok) {
            throw new Error('Failed to get authentication type');
          }

          const authTypeData = await authTypeResponse.json();
          const authType = authTypeData.authentication_type;
          let logoutUrl;
          if (authType === 'Microsoft') {
            logoutUrl = '/microsoft/logout';
          } else if (authType === 'Google') {
            logoutUrl = '/google/logout';
          } else {
            throw new Error('Unknown authentication type');
          }

          const response = await fetch(logoutUrl, {
            method: 'GET',
            credentials: 'include',
          });
          if (response.ok) {
            window.location.href = '/';
          } else {
            console.error('Logout failed:', response);
          }
        } catch (error) {
          console.error('There was an error logging out', error);
        }
        break;
  

      case 'Browse':
        // Add logic for "Browse" option here
        break;

      default:
        break;
    }
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsUserProfileDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

 
  const handleDownloadPDF = async () => {
    setIsDownloading(true); // Start loader
    try {
      const element = document.querySelector('.audit-container'); // Select the page content
      const options = {
        margin: 0,
        filename: 'Audit_Manager_Dashboard.pdf',
        image: { type: 'png', quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'in', format: 'a2', orientation: 'landscape' }, // A2 size in landscape
      };

      await html2pdf().set(options).from(element).save();
    } catch (error) {
      console.error('Error generating PDF:', error);
    } finally {
      setIsDownloading(false); // Stop loader
    }
  };

  return (
    <div className="audit-header">
         <h1 className="audit-header-title">Audit Manager Dashboard</h1>
         <div className="audit-complieaze-logo">
        <div className="audit-logo">
            <span>Compli</span>
            <span>eaze</span>
        </div>
        <h2 className="audit-titlee">Regulatory compliance assistant</h2>
        </div>
        <div className="user-profile-container">
        <button
        className="download-pdf-button"
        onClick={handleDownloadPDF}
        disabled={isDownloading} 
      >
        {isDownloading ? (
          <div className="spinner"></div> 
        ) : (
          'Download PDF'
        )}
      </button>
            {userDetails ? (
              <>
                <button
                  id="profile-btn"
                  className="user-profile-button"
                  type="button"
                  onClick={toggleUserProfileDropdown}
                >
                  <div className="user-profile-initials">
                    {userDetails.firstname[0].toUpperCase()}
                    {userDetails.lastname[0].toUpperCase()}
                  </div>
                  <div className="user-tooltip">
                    {userDetails.firstname} {userDetails.lastname}
                  </div>
                </button>
              </>
            ) : (
              <button
                id="profile-btn"
                className="user-profile-button"
                type="button"
                onClick={toggleUserProfileDropdown}
              >
                <div className="user-profile-initials">--</div>
              </button>
            )}

            {isUserProfileDropdownOpen && (
              <div className="user-profile-dropdown">
                <div onClick={() => handleUserOptionClick("Prompt")}>
                  Prompt
                </div>
                {isSuperAdmin && (
                  <div onClick={() => handleUserOptionClick("Admin")}>
                    Admin
                  </div>
                )}
                <div onClick={() => handleUserOptionClick("Logout")}>
                  Logout
                </div>
              </div>
            )}
          </div>
    </div>
  );
};

export default UserProfileDropdown;
