import React from "react";
import "./SummaryCard.css";

const SummaryCard = ({ title, value }) => {
  return (
    <div className="audit-summary-card">
      <h3 className="audit-summary-card-title">{title}</h3>
      <p className="audit-summary-card-value">{value}</p>
    </div>
  );
};

export default SummaryCard;
