import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Login from './Components/Login/Login';
import ChatUI from './Components/Chatapp/Chat';
import Admin from './Components/Admin/Admin';
import LandingPage from './Components/Landing_Page/Landing_Page';
import ErrorPage from './Components/Error/Error';
import Audit from './Components/Audit/Audit';
// import SignInPage from './Components/Register/Register';

function App() {
  return (
    <div className="app">
      <Router>
        <Switch>
          {/* <Route path="/register" exact component={SignInPage} /> */}
          <Route path="/chat" exact component={ChatUI} />
          <Route path="/access_denied" exact component={ErrorPage} />
          <Route path="/landingpage" exact component={LandingPage} />
          <Route path="/admin" exact component={Admin} />
          <Route path="/audit-dashboard" exact component={Audit}/>
           <Route path="/" component={Login} /> 
        </Switch>
      </Router>
    </div>
  );
}

export default App;
