import React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import Toolbar from "@mui/material/Toolbar";
import { SaveAlt } from "@mui/icons-material";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import "./DetailedLogsTable.css";

const columns = [
  { id: "username", label: "User", minWidth: 170 },
  { id: "command", label: "Command", minWidth: 170 },
  { id: "regulator", label: "Regulator", minWidth: 170 },
  { id: "details", label: "Details", minWidth: 170 },
  { id: "timestamp", label: "Date", minWidth: 170 },
];

export default function DetailedLogsTable({
  logs,
  totalCount,
  page,
  rowsPerPage,
  onPageChange,
  onRowsPerPageChange,
}) {
  console.log("DetailedLogsTable Props:", { logs, totalCount, page, rowsPerPage });

  const getDisplayName = (command) => {
    return command === "action" ? "checklist" : command;
  };
  
  // Prepare rows for display
  const rows = logs.map((log) => ({
    username: log.username,
    command: getDisplayName(log.command),
    regulator: log.regulator,
    details: formatDetails(log.parameter),
    timestamp: log.timestamp,
  }));

  console.log("Rows to Display:", rows);

  // Function to format details for display
  function formatDetails(parameter) {
    if (!parameter) return "N/A";
    return (
      <div className="details-container">
        {Object.entries(parameter).map(([key, value], idx) => (
          <div key={idx} className="details-item">
            <span className="details-label">{key}:</span>
            <span className="details-value">{value}</span>
          </div>
        ))}
      </div>
    );
  }

  // Define the base rowsPerPageOptions
  const baseRowsPerPageOptions = [10, 25, 50, 100];

  // Dynamically add totalCount to rowsPerPageOptions if it's not already included and valid
  let customRowsPerPageOptions = baseRowsPerPageOptions.filter(option => option <= totalCount);

  if (totalCount > 0 && !baseRowsPerPageOptions.includes(totalCount)) {
    customRowsPerPageOptions.push(totalCount);
  }

  // Ensure all options are numbers and remove any NaN or invalid entries
  customRowsPerPageOptions = customRowsPerPageOptions
    .map(option => Number(option))
    .filter(option => !isNaN(option) && option > 0);

  // If no options are available, default to showing all
  if (customRowsPerPageOptions.length === 0 && totalCount > 0) {
    customRowsPerPageOptions = [totalCount];
  }

  console.log("Custom Rows Per Page Options:", customRowsPerPageOptions);

  // Export to Excel function
  const exportToExcel = () => {
    // Prepare the data for export
    const exportData = logs.map((log) => ({
      User: log.username,
      Command: log.command,
      Regulator: log.regulator,
      Details: log.parameter ? JSON.stringify(log.parameter, null, 2) : "N/A",
      Date: log.timestamp,
    }));

    // Create a worksheet from the data
    const worksheet = XLSX.utils.json_to_sheet(exportData);

    // Create a new workbook and append the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Logs");

    // Generate buffer
    const workbookOut = XLSX.write(workbook, { bookType: "xlsx", type: "array" });

    // Create a Blob from the buffer
    const blob = new Blob([workbookOut], { type: "application/octet-stream" });

    // Trigger the download
    saveAs(blob, "logs.xlsx");
  };

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      {/* Toolbar with Export Button */}
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-between",
          paddingLeft: 2,
          paddingRight: 1,
        }}
      >
        <div></div> {/* Placeholder for alignment */}
        <Button
          variant="contained"
          color="primary"
          startIcon={<SaveAlt />}
          onClick={exportToExcel}
        >
          Export to Excel
        </Button>
      </Toolbar>
  
      {/* Table Container */}
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="detailed logs table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  style={{ minWidth: column.minWidth }}
                  align="center"
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.length === 0 ? (
              <TableRow>
                <TableCell colSpan={columns.length} align="center">
                  No logs available.
                </TableCell>
              </TableRow>
            ) : (
              rows.map((row, index) => (
                <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                  {columns.map((column) => {
                    const value = row[column.id];
                    return (
                      <TableCell key={column.id} align="center">
                        {value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
  
      {/* Pagination */}
      {customRowsPerPageOptions.length > 0 && (
        <TablePagination
          rowsPerPageOptions={customRowsPerPageOptions}
          labelRowsPerPage="Rows per page:"
          labelDisplayedRows={({ from, to, count }) =>
            rowsPerPage !== totalCount
              ? `${from}-${to} of ${count}`
              : `1-${count} of ${count}`
          }
          component="div"
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
          // Disable the next and previous buttons when all rows are displayed
          nextIconButtonProps={{ disabled: rowsPerPage === totalCount }}
          backIconButtonProps={{ disabled: rowsPerPage === totalCount }}
        />
      )}
    </Paper>
  );
}