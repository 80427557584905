import React from "react";
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from "recharts";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import { FaDownload } from "react-icons/fa"; // Importing the download icon
import "./CommandWiseUsage.css";

const generateColor = (index) => {
  const colors = [
    "#8884d8",
    "#82ca9d",
    "#ffc658",
    "#ff8042",
    "#ff7300",
    "#66cdaa",
    "#db7093",
    "#6a5acd",
  ];
  return colors[index % colors.length];
};

const getDisplayName = (command) => {
  return command === "action" ? "Checklist" : command.charAt(0).toUpperCase() + command.slice(1);
};

const CommandWiseUsage = ({ commandUsage, user_count }) => {
  const data =
    commandUsage.length > 0
      ? commandUsage
      : [{ command: "No commands available", usage_count: 0 }];

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      return (
        <div className="audit-custom-tooltip">
          <p>{`Command: ${getDisplayName(data.command)}`}</p>
          <p>{`Usage Count: ${data.usage_count}`}</p>
        </div>
      );
    }
    return null;
  };

  // Function to handle Excel download
  const handleDownload = () => {
    // Prepare data for the first sheet (Command Usage)
    const excelData = data.map((item) => ({
      Command: getDisplayName(item.command),
      "Usage Count": item.usage_count,
    }));

    // Prepare data for the second sheet (Short Summary)
    const summaryData = [
      { Description: "Total Users accessed the application", Value: user_count },
      {
        Description: "Total Checklists generated",
        Value:
          data.find((item) => item.command === "action")?.usage_count || 0,
      },
      {
        Description: "Total Documents browsed",
        Value:
          data.find((item) => item.command === "browse")?.usage_count || 0,
      },
      {
        Description: "Total Changes in documents referred to",
        Value:
          data.find((item) => item.command === "changes")?.usage_count || 0,
      },
      {
        Description: "Total References in documents referred to",
        Value:
          data.find((item) => item.command === "reference")?.usage_count || 0,
      },
      {
        Description: "Total Documents uploaded",
        Value:
          data.find((item) => item.command === "upload_docs")?.usage_count ||
          0,
      },
    ];

    // Create worksheets
    const worksheet1 = XLSX.utils.json_to_sheet(excelData);
    const worksheet2 = XLSX.utils.json_to_sheet(summaryData);

    // Create a new workbook and append the worksheets
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet1, "Command Usage");
    XLSX.utils.book_append_sheet(workbook, worksheet2, "Short Summary");

    // Generate buffer
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    // Create a blob and trigger download
    const dataBlob = new Blob([excelBuffer], {
      type:
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });
    saveAs(dataBlob, "command_usage.xlsx");
  };

  return (
    <div className="audit-command-usage">
      <div className="command-header">
        {/* Left Placeholder */}
        <div className="header-placeholder"></div>

        {/* Centered Title */}
        <h2>Command Wise Usage</h2>

        {/* Right Download Icon */}
        <div
          className="download-icon"
          onClick={handleDownload}
          title="Download Excel"
          aria-label="Download Excel"
          role="button"
          tabIndex={0}
          onKeyPress={(e) => {
            if (e.key === "Enter" || e.key === " ") {
              handleDownload();
            }
          }}
        >
          <FaDownload size={20} color="#0000FF" />
        </div>
      </div>
      <ResponsiveContainer width="100%" height={300}>
        <PieChart>
          <Pie
            data={data}
            dataKey="usage_count"
            nameKey="command"
            cx="50%"
            cy="50%"
            outerRadius={120}
            label
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={generateColor(index)} />
            ))}
          </Pie>
          <Tooltip content={<CustomTooltip />} />
        </PieChart>
      </ResponsiveContainer>
      <div className="audit-legend">
        {data.map((entry, index) => (
          <div key={entry.command} className="audit-legend-item">
            <span
              className="audit-legend-color"
              style={{ backgroundColor: generateColor(index) }}
            />
            {getDisplayName(entry.command)}
          </div>
        ))}
      </div>
    </div>
  );
};

export default CommandWiseUsage;
